import React, { lazy, FC } from "react";
import { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import PageNotFound from "../pages/error/PageNotFound";
import { Role } from "../shared/enums/Role";
import { AppContext } from "../store/Context";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

//Loading the Pages
const SettingPage = lazy(() => import("../pages/setting/Setting"));
const ErrorPage = lazy(() => import("../pages/error/Error"));
const HomePage = lazy(() => import("../pages/home/Home"));
const MeetingPage = lazy(() => import("../pages/dealer-meeting/Meeting"));

interface AppRoutesProps {

}

//Defining the Routes
const AppRoutes: FC = (props: AppRoutesProps) => {
  const { state, dispatch } = useContext(AppContext);
  return (
    <Switch>
      <PublicRoute path="/error" exact={true} component={ErrorPage} restricted={false} />
      <PrivateRoute path="/setting" exact={true} component={SettingPage} />
      <PublicRoute path="/" exact={true} component={HomePage} restricted={false} />
      <PrivateRoute path="/host" exact={true} component={MeetingPage} />
      <PrivateRoute path="/guest" exact={true} component={MeetingPage} />
      <Route path="*" >
        <PageNotFound/>
      </Route>
    </Switch>
  );
};

export default AppRoutes;
