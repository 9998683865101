import React, { createContext, useReducer, Dispatch, useEffect } from "react";
import {
  userReducer,
  pageReducer,
  meetingReducer,
  messageReducer,
} from "./Reducers";
import initialState, { IState } from "./InitialState";

const AppContext = createContext<{
  state: IState;
  dispatch: Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

const mainReducer = ({ user, page, meeting, messages }: IState, action: any) => ({
  user: userReducer(user, action),
  page: pageReducer(page, action),
  meeting: meetingReducer(meeting, action),
  messages: messageReducer(messages, action),
});

const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState, () => {
    const userData = sessionStorage.getItem("winUser");
    const publisherData = sessionStorage.getItem("publisher");
    let data = userData
      ? {
        ...initialState,
        user: JSON.parse(userData),
      }
      : initialState;
    // console.log("data in ctx", data);
    // console.log("publisherData in ctx", publisherData);
    data = publisherData
      ? {
        ...data,
        meeting: {
          ...data.meeting,
          publisher_data: JSON.parse(publisherData),
        },
      }
      : data;
    // console.log("data in ctx", data);
    return data;
  });
  useEffect(() => {
    sessionStorage.setItem("winUser", JSON.stringify(state.user))
    sessionStorage.setItem(
      "publisher",
      JSON.stringify(state.meeting.publisher_data)
    );
  }, [state.user, state.meeting.publisher_data]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
