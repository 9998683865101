import { CircularProgress } from "@material-ui/core";
import React, { useContext } from "react";
import { FunctionComponent } from "react";
import { PageState } from "../../shared/enums/PageState";
import { AppContext } from "../../store/Context";
import "./OverlayLoading.css";

const OverlayLoading: FunctionComponent<{forceShow?: boolean}> = props => {
    const { state, dispatch } = useContext(AppContext);
    return <React.Fragment>{
                (state.page.state==PageState.loading || props.forceShow) && 
                <CircularProgress disableShrink className="loading" />
            }</React.Fragment>;
}
  
  export default OverlayLoading;