export enum Role {
    admin = "admin",
    supervisor = "supervisor",
    host = "host",
    guest = "guest"
}

export enum AgoraRole {
    host = "host",
    audience = "audience"
}