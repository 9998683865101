import { IAgoraRTCRemoteUser, ILocalVideoTrack } from "agora-rtc-sdk-ng";
import { PageState } from "../shared/enums/PageState";
import { Role } from "../shared/enums/Role";

/**
 * User State
 */
export interface IUserState {
  name: string;
  is_logged_in: boolean | undefined;
  logout_effect: boolean;
  role: string;
  token: string | undefined;
  room: string | undefined;
}

export interface IPageState {
  state: PageState;
  errorMessage: string;
}

/**
 * Meeting State
 */
interface IScreenState {}

export interface IMessageState {
  type: string;
  msg: string;
  id: string;
  self: string;
  fileId: any;
}

export interface IMeetingState {
  publisher_data: {
    is_audio_enabled: boolean;
    is_video_enabled: boolean;
    main_content: string;
    share_screen: boolean;
    in_main_container: Boolean;
  };
  remote_users: IAgoraRTCRemoteUser[];
  screenShared: ILocalVideoTrack | undefined;
  participant_state: {
    is_audio_enabled: boolean;
    is_video_enabled: boolean;
  };
}
/**
 * App State interface
 */
export interface IState {
  user: IUserState;
  page: IPageState,
  meeting: IMeetingState;
  messages: IMessageState[];
}
/**
 * Initialize intial app state
 */
const initialState: IState = {
  user: {
    name: "",
    is_logged_in: undefined,
    logout_effect: false,
    role: Role.guest,
    token: undefined,
    room: undefined
  },
  page: { state: PageState.loaded, errorMessage: "" },
  meeting: {
    publisher_data: {
      is_audio_enabled: true,
      is_video_enabled: true,
      share_screen: false,
      main_content: "3dview", //3 possibilities : 3dview | host | screen
      in_main_container: false,
    },
    remote_users: [],
    screenShared: undefined,
    participant_state: {
      is_audio_enabled: true,
      is_video_enabled: true,
    },
  },
  messages: [],
  
};

export default initialState;
