import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AppContext } from '../store/Context';

const PublicRoute = ({component: Component, restricted, ...rest}) => {
    const { state, dispatch } = useContext(AppContext);
    

    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route {...rest} render={props => (
            state.user.is_logged_in && restricted ?
                <Redirect to="/dashboard" />
            : <Component {...props} />
        )} />
    );
};

export default PublicRoute;