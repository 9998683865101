import { AcUnitTwoTone } from "@material-ui/icons";
import { PageState } from "../shared/enums/PageState";
import initialState, { IMeetingState, IMessageState, IPageState, IState, IUserState } from "./InitialState";

export const userReducer = (state: IUserState, action: any) => {
  switch (action.type) {
    case "USER_LOGIN":
      return {
        ...state,
        name: action.payload.uid,
        is_logged_in: true,
        logout_effect: false,
        role:action.payload.role,
        token: action.payload.token,
        room: action.payload.room
      };
    case "USER_LOGOUT":
      return {
        ...state,
        logout_effect: true,
      };
    case "USER_LOGOUT_COMPLETED":
      return {
        ...state,
        name: "",
        is_logged_in: false,
        logout_effect: false
      };
    default:
      return state;
  }
};

export const pageReducer = (state: IPageState, action: any) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        state: PageState.loading, 
        errorMessage: ""
      };
    case "SET_LOADED":
      return {
        ...state,
        state: PageState.loaded, 
        errorMessage: ""
      };
    case "SET_ERROR":
      return {
        ...state,
        state: PageState.error, 
        errorMessage: action.payload.errorMessage
      };
    default:
      return state;
  }
}

// export const errorMessageReducer = (state: any, action: any) => {
//   switch (action.type) {
//     case "SET_ERROR_MESSAGE":
//       return {...state, errorMessage: action.message};
//     default:
//       return state;
//   }
// }

export const meetingReducer = (state: IMeetingState, action: any) => {
  switch (action.type) {
    case "ADD_USER":
      return { ...state, remote_users: action.payload.remoteStreams };
    case "AUDIO_STATE":
      return {
        ...state,
        publisher_data: {
          ...state.publisher_data,
          is_audio_enabled: action.payload.isEnabled,
        },
      };
    case "VIDEO_STATE":
      return {
        ...state,
        publisher_data: {
          ...state.publisher_data,
          is_video_enabled: action.payload.isEnabled,
        },
      };
    case "SCREEN_STATE":
      return {
        ...state,
        publisher_data: {
          ...state.publisher_data,
          share_screen: action.payload.isEnabled,
        },
      };
    case "ADD_SCREEN":
      return { ...state, screen_shared: action.payload.screenShared };
    case "SET_MAIN_CONTENT_TYPE":
      return {
        ...state,
        publisher_data: {
          ...state.publisher_data,
          main_content: action.payload.mainContentType,
        },
      };
    case "TOGGLE_PARTICIPANT_VIDEO":
      return {
        ...state,
        participant_state: {
          ...state.participant_state,
          is_video_enabled: action.payload.isEnabled,
        },
      };
    case "TOGGLE_PARTICIPANT_AUDIO":
      return {
        ...state,
        participant_state: {
          ...state.participant_state,
          is_audio_enabled: action.payload.isEnabled,
        },
      };

      case "MAIN_SCREEN":
        return {
          ...state,
          publisher_data:{
            ...state.publisher_data,
            in_main_container:true
          }
        };
      case "HIDE_FROM_SCREEN":
        return {
          ...state,
          publisher_data:{
            ...state.publisher_data,
            in_main_container:false
          }
        };

    case "USER_LOGOUT":
      return {
        publisher_data: {
          is_audio_enabled: true,
          is_video_enabled: true,
          share_screen: false,
          main_content: "3dview",
        },
        remote_users: [],
        screenShared: undefined,
        participant_state: {
          is_audio_enabled: true,
          is_video_enabled: true,
        },
      };
    default:
      return state;
  }
};

export interface messageReducerInterface {
  msg: string;
  type: string;

}

export const messageReducer = (state: IMessageState[], action: any) => {
  switch (action.type) {
    case "ADD_MESSAGE":
      return [
        ...state,
        {
          msg: action.payload.msg,
          type: action.payload.type,
          fileId: action.payload.fileId,
          id: action.payload.id,
          self: action.payload.self,
        },
      ];
    case "USER_LOGOUT":
      return [];

    default:
      return state;
  }
};

export const switchReducer = (state: any, action: any) => {
  switch (action.type) {
   
    case "USER_LOGOUT":
      return false;
    default:
      return state;
  }
};
