import React, { useContext } from "react";
import { FC } from "react";
import { Redirect } from "react-router-dom";
import { PageState } from "../../shared/enums/PageState";
import { AppContext } from "../../store/Context";

const ErrorBoundaryFromAppState: FC = ({ children }) => {

    const { state, dispatch } = useContext(AppContext);

    return <React.Fragment>
        { state.page.state!==PageState.error && children }
        { state.page.state===PageState.error && <Redirect to="/error" /> }
    </React.Fragment>;
}

export default ErrorBoundaryFromAppState;