import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { AppProvider } from "./store/Context";
import AppRoutes from "./routes/Index";
import "./assets/sass/_themes.scss";
import "./assets/sass/_reset.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorBoundaryFromAppState from "./components/error-boundary/ErrorBoundaryFromAppState";
import OverlayLoading from "./components/overlay-loading/OverlayLoading";


function App() {
  return (
    <AppProvider>
      <BrowserRouter>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ErrorBoundaryFromAppState>
          <Suspense fallback={<OverlayLoading forceShow />}>
            {/* <Auth> */}
              <AppRoutes />
            {/* </Auth> */}
            <OverlayLoading />
          </Suspense>
        </ErrorBoundaryFromAppState>
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;
