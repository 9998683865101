import React, { FC } from "react";

const PageNotFound: FC = () => {

    return <div>
        Page not found
    </div> 

}

export default PageNotFound;